import elusive_gesha_tree from "../assets/home-sec1-2.webp";
import naturalForest from "../assets/home-sec1-4.webp";
import tribe_leader_2 from "../assets/home-sec2-2.webp";
import tribe_leader_1 from "../assets/home-sec2-3.webp";
import yohannes from "../assets/home-sec2-4.webp";
import quote_bg from "../assets/quote-bg.webp";
import {motion} from "framer-motion";
import "../sass/home.scss";

export default function Home() {
    return (
        <main className="home">
            <section className="home-intro container-fluid row m-0 row-cols-1 row-cols-lg-2 gap-5 gap-lg-0">
                <div className="col d-flex justify-content-center">
                    <motion.div
                        initial={{opacity: 0}}
                        transition={{type: "tween", duration: 1.5}}
                        whileInView={{opacity: 1}}
                        className="image-grid">
                        <motion.img loading="lazy"
                            initial={{opacity:0, x: -100, y: -100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{opacity:1, x: 0, y: 0}}
                            src={elusive_gesha_tree}
                            className="top-image"
                            alt="coffee plant"/>
                        <motion.img loading="lazy"
                            initial={{opacity:0, x: -100, y: 100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{opacity:1, x: 0, y: 0}}
                            src={naturalForest}
                            className="bottom-image"
                            alt="grasshopper on a leaf"/>
                    </motion.div>
                </div>
                <div className="intro-text d-flex align-items-center justify-content-center text-center col">
                    <motion.h1
                        initial={{opacity: 0, y: -200}}
                        transition={{type: "tween", duration: 1}}
                        whileInView={{opacity: 1, y: 0}}
                        className="mb-3">
                        The Original Gesha
                    </motion.h1>
                    <motion.p
                        initial={{x:100, y: 100}}
                        transition={{type: "tween", duration: 1}}
                        whileInView={{x:0, y: 0}}
                    >
                        In 2010, Yohannes Samuel embarked on an ambitious expedition to rediscover the original source trees of the renowned Gesha coffee that were hidden in the rugged terrain of the Gorigesha mountain forest. Unspoiled by human activity, the captivating beauty of the surroundings, and the vastness of the mountain forest left an indelible impression on him. This journey sparked a dream to preserve the sacred land by cultivating the very coffee tree that is indigenous to the forest.
                    </motion.p>
                    <motion.p
                        initial={{y: 100}}
                        transition={{type: "tween", duration: 1}}
                        whileInView={{y: 0}}
                    >
                        After developing his vision over several years, Yohannes was entrusted with the crucial responsibility of protecting the forest by the tribal leaders. This was a pivotal moment that marked the beginning of his ambitious project to establish one of the largest natural Gesha coffee plantations in the world. Yohannes's efforts not only protected the forest and its biodiversity but also helped to create sustainable livelihoods for the local communities.
                    </motion.p>

                    <motion.h2
                        initial={{opacity: 0, y: 200}}
                        transition={{type: "tween", duration: 1}}
                        whileInView={{opacity: 1, y: 0}}
                        className="">Welcome to Gori Gesha. Home of the Original Gesha.
                    </motion.h2>
                </div>
            </section>
            <section className="home-quote text-center d-flex align-items-center">
                <motion.figure
                    id="quote"
                    initial={{x: -100, opacity: 0}}
                    transition={{type: "tween", duration: 1}}
                    whileInView={{x: 0, opacity: 1}}
                >
                    <h2 className="text-white">
                        <span>Gesha</span> coffee, sometimes referred to as <span>Geisha coffee</span>, is a variety of
                        coffee tree that originated in the Gori Gesha forest, Ethiopia
                    </h2>
                    <figcaption className="blockquote-footer text-white m-0">
                        <cite title="Source Title">Wikipedia</cite>
                    </figcaption>
                </motion.figure>
            </section>
            <section
                className="home-info d-flex flex-column flex-lg-row container-fluid row m-0 row-cols-lg-2 gap-5 gap-lg-0">
                <motion.div
                    initial={{opacity: 0}}
                    transition={{type: "tween", duration: 1.5}}
                    whileInView={{opacity: 1}}
                    className="text-content d-flex align-items-center justify-content-center text-center col">
                    <div>
                        <motion.h1
                            initial={{y: -100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{y: 0}}
                            className="py-5">
                            The Guardians of Gesha
                        </motion.h1>
                        <motion.p
                            initial={{y: 100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{y: 0}}
                        >
                            In 2017, Yohannes acquired 600 hectares of virgin land within the Gorigesha mountain forest. Prior to this, he had successfully cultivated Gesha coffee on a nearby farm. During this time, Yohannes established a trusted relationship with the leaders of the surrounding tribe, known as the "Minit tribe". They developed this bond through many years of exchanging thoughts and ideas about preserving not only the integrity of the forests but also its harmony with the local people. It was through this bond that they entrusted him with the responsibility of caring for one of the few remaining treasures of Gesha - the Gorigesha mountain forest.
                        </motion.p>
                        <motion.p
                            initial={{y: 100}}
                            transition={{type: "tween", duration: 1.2}}
                            whileInView={{y: 0}}
                        >
                            After acquiring the land and receiving the blessing of tribal leaders, Yohannes and his team began to cultivate 180 hectares of the virgin Gorigesha forest with the native Gesha coffee varietal known as Gori Gesha. This coffee varietal is unique and found exclusively within the Gorigesha forest. The lush ecology of the forest allowed for chemical-free and fertilizer-free farming practices, thus safeguarding the forest's natural environment. By the end of 2018, they had planted over 1 million Gesha trees on 475 hectares of the Gorigesha mountain forest, producing the original varietal of all other highland Gesha coffees and establishing the Gorigesha Mountain Coffee Plantation.
                        </motion.p>
                        <motion.p
                            initial={{y: 100}}
                            transition={{type: "tween", duration: 1.4}}
                            whileInView={{y: 0}}
                        >
                            Driven by a love for the land and a deep-rooted commitment to preserving the ecosystem, Yohannes and his team have dedicated themselves to cultivating coffee trees in a manner that sustains the natural balance of the forest. The result is a symbiotic relationship between the forest, the people of the area, and the Gorigesha Mountain Coffee Plantation.
                        </motion.p>
                    </div>
                </motion.div>
                <div className="col d-flex justify-content-center">
                    <div className="image-grid">
                        <motion.img loading="lazy"
                            initial={{x: 100, y: -100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{x: 0, y: 0}}
                            src={tribe_leader_1}
                            className="top-image"
                            alt="Minit Tribe Leader"
                        />
                        <motion.img loading="lazy"
                            initial={{x: 100, y: 100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{x: 0, y: 0}}
                            src={tribe_leader_2}
                            className="bottom-image"
                            alt="Minit Tribe Leader"
                        />
                        <motion.img loading="lazy"
                            initial={{x: 100, y: 100}}
                            transition={{type: "tween", duration: 1}}
                            whileInView={{x: 0, y: 0}}
                            src={yohannes}
                            className="third-image "
                            alt="Ato Yohannes Samuel"
                        />
                    </div>
                </div>
            </section>
        </main>
    )
}