import goriGeshaLogo from "../assets/gori-gesha-logo.svg";
import {Link} from "react-router-dom";
import * as ROUTES from "../constants/routes";
import "../sass/footer.scss"
import Logo from '../assets/gori-gesha-logo-main.png'

export default function () {
    return (
        <footer className="d-flex flex-column flex-md-row text-start justify-content-lg-between">
            <div className="footer-logo col-12 col-md-4 text-start">
                <img loading="lazy" alt="the gori gesha logo" src={Logo}/>
                <p className="py-3">
                    Welcome to Gori Gesha. The home of the Original Gesha.
                </p>
            </div>
            <div className="footer-content col-12 col-md-7 p-0 d-flex flex-column flex-md-row justify-content-md-between ">
                <div className="footer-navigation">
                    <h4>Navigation</h4>
                    <Link to={ROUTES.HOME}>Home</Link>
                    <Link to={ROUTES.SUSTAINABILITY}>
                        Sustainability
                    </Link>
                    <Link to={ROUTES.TIMELINE}>
                        Our Story
                    </Link>
                </div>
                <div className="footer-more">
                    <h4>More</h4>
                    <Link to={`${ROUTES.TIMELINE}/#history-of-gesha`}>
                        History of Gesha
                    </Link>
                    <Link to={`${ROUTES.TIMELINE}/#gori-gesha`}>
                        Gori Gesha Mountain Coffee Plantation
                    </Link>
                    <Link to={`${ROUTES.TIMELINE}/#minit-tribe`}>
                        Minit Tribe
                    </Link>
                </div>
                <div className="footer-contact">
                    <h4>Get In Touch</h4>
                    <Link to={ROUTES.CONTACT}>
                        Contact Us
                    </Link>
                </div>
            </div>
        </footer>
    )
}