import {motion} from "framer-motion";
import "../sass/sustainability.scss";
import planting_crops from '../assets/sustailability-1.webp'
import worker_1 from '../assets/sustailability-2.webp'
import worker_2 from '../assets/sustailability-3.webp'
import gas_station from '../assets/sustailability-4.webp'

export default function () {
    return (
        <main className="sustainability">
            <section className="sustainability-banner d-flex text-white text-center">
                <h1 className="align-self-center">Sustainability</h1>
            </section>
            <section className="sustainability-section d-flex flex-column flex-lg-row align-items-center">
                <motion.img loading="lazy"
                    initial={{x: -300, opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{duration: 1}}
                    className="left-collapse col-12 col-lg-6 text-white"
                    src={planting_crops}
                    alt="Planting crops naturally"
                />

                <motion.div
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    transition={{delay: 0.5, duration: 1}}
                    className="right-text col-12 col-lg-6">
                    <p className="">
                        Despite the increasing trend of inorganic Gesha coffee cultivation worldwide, Gorigesha Mountain Coffee Plantation stands firm in our commitment to preserve and maintain our coffee trees without the use of harmful chemicals or fertilizers. We understand the complexity and interdependence of the ecosystem and recognize our obligation to safeguard it. To fulfill this responsibility, we have decided to limit the number of trees planted to fewer than 2,000 per hectare, ensuring the soil remains fertile and nutrient-rich for our coffee trees to grow in a sustainable manner.
                    </p>
                </motion.div>
            </section>
            <section className={'sustainability-mid-section col-10 d-flex flex-column flex-lg-row justify-content-lg-between align-items-center'}>
                <motion.div className={'side-1'} ></motion.div>
                <motion.p className={'text-start text-lg-center'}
                          initial={{x: 300, opacity: 0}}
                          whileInView={{x: 0, opacity: 1}}
                          transition={{duration: 1}}>
                    To us, achieving sustainability means more than just environmental stewardship. It also requires a genuine dedication to the people involved in the coffee production process. At Gorigesha Mountain Coffee Plantation, we consider our staff to be part of our family, and we are committed to treating them as such. This includes paying our workers a fair wage and ensuring that they and their families have access to essential needs such as healthcare and education. We believe that by investing in our staff, we are not only doing the right thing but also securing the long-term success of our plantation.
                </motion.p>
                <motion.div className={'side-2'} ></motion.div>
            </section>
            <section className="sustainability-section d-flex flex-column-reverse flex-lg-row align-items-center">
                <motion.div
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    transition={{delay: 0.5, duration: 1}}
                    className="left-text col-12 col-lg-6 order-lg-0 order-1">
                    <p>
                        At Gorigesha Mountain Coffee Plantation, we are dedicated to improving the lives of the people in Gori by going beyond just the cultivation of coffee. We have already built the first gas station in the city of Jemu, and in the coming years, we plan to renovate the local school and build a modern hospital to provide universal education and lifesaving healthcare to the inhabitants of the region. We understand the importance of nurturing the entire environment around us, and we are committed to making a positive impact on both the community and the natural world.
                    </p>
                </motion.div>
                <motion.img loading="lazy"
                    initial={{x: 300, opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{duration: 1}}
                    className="right-collapse text-white col-12 col-lg-6 order-0 order-lg-1"
                    src={gas_station}
                    alt="Gas station in Jemu"
                />
            </section>
        </main>
    )
}