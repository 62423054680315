import {Outlet, useLocation} from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {useEffect} from "react";

export default function SharedLayout() {
    const {pathname} = useLocation()

    useEffect(()=>{
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    },[pathname])

    return (
        <div className="shared-layout">
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    )
}