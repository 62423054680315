import {BiPhoneCall} from "react-icons/bi";
import {FaEnvelopeOpenText} from "react-icons/fa";
import {ReactComponent as TypeWriterIcon} from "../assets/typewriter.svg";
import "../sass/contact.scss";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useState} from "react";

export default function () {
    const fax = "+251 116 676410";
    const phoneNumber = "+251 116 676175";
    const email = "info@gorigeshacoffee.com";
    const [copyFaxText, setCopyFaxText] = useState("Copy");
    const [copyPhoneText, setCopyPhoneText] = useState("Copy");
    const [copyEmailText, setCopyEmailText] = useState("Copy");

    const [contactInfo, setContactInfo] = useState({
        firstName: "",
        lastName: "",
        company:"",
        email: "",
        foundUs: {
            advertisement: false,
            googleSearch: false,
            throughAFriend: false,
            socialMedia: false,
            other: {
                subject: "",
                details: "",
            },
        },
    });

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const handleChange = (e) => {
        console.log(e.target.name," ",e.target.value)
        setContactInfo((prevState) => {
            // if (e.target.name === "foundUs") {
            if (e.target.id === "controlOtherInput" || e.target.id === "controlSubjectInput") {
                console.log('check')
                return {
                    ...prevState,
                    foundUs: {
                        ...prevState.foundUs,
                        other: {
                            ...prevState.foundUs.other,
                            [e.target.name]: e.target.value,
                        },
                    },
                }
            } else {
                // return {
                //     ...prevState,
                //     foundUs: {
                //         ...prevState.foundUs,
                //         [e.target.id]: e.target.checked,
                //     },
                // }
                return {...prevState, [e.target.name]: e.target.value}
            }
            // } else {
            // }
        });
    }

    const renderFaxTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copyFaxText}
        </Tooltip>
    );

    const renderPhoneTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copyPhoneText}
        </Tooltip>
    );

    const renderEmailTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copyEmailText}
        </Tooltip>
    );

    const handleSubmit = (e) => {
        console.log(e)
    }

    return (
        <div className="contact d-flex flex-column flex-lg-row row m-0 row-cols-2 justify-content-between">
            <section className="d-flex flex-column col-12 col-lg-5">
                <h1>Get <span>in Touch</span></h1>
                <p className="mb-3 text-muted">Fill in the form below for any inquiries you may have.</p>
                <form
                    action={'mailto:info@gorigeshacoffee.com?subject=' + encodeURIComponent(contactInfo.foundUs.other.subject) + '&email=' + encodeURIComponent(contactInfo.email) + '&body=' + encodeURIComponent(contactInfo.foundUs.other.details)}
                    method="POST" encType="text/plain" name="gorigesha.com" id="form">
                    <div className="mb-3">
                        <label htmlFor="contactFirstNameInput" className="form-label">
                            First Name*
                        </label>
                        <input type="text" name="firstName" className="form-control" value={contactInfo.firstName}
                               required
                               id="contactFirstNameInput" placeholder="John"
                               onChange={handleChange}/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="contactLastNameInput" className="form-label">Last Name*</label>
                        <input type="text" name="lastName" className="form-control" value={contactInfo.lastName}
                               required id="contactLastNameInput"
                               placeholder="Doe" onChange={handleChange}/>
                    </div>


                    <div className="mb-3">
                        <label htmlFor="contactEmailInput" className="form-label">Email*</label>
                        <input type="email" name="email" className="form-control" value={contactInfo.comp} required
                               id="contactEmailInput"
                               placeholder="JohnDoe@email.com" onChange={handleChange}/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="contactCompanyInput" className="form-label">Company*</label>
                        <input type="text" name="company" className="form-control" value={contactInfo.company}
                               required id="contactCompanyInput"
                               placeholder="Company Name" onChange={handleChange}/>
                    </div>

                    {/*<div className={'found-us'}>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <label className="form-check-label" htmlFor="advertisement">Advertisement</label>*/}
                    {/*        <input className="form-check-input" name="foundUs" type="checkbox"*/}
                    {/*               id="advertisement" checked={contactInfo.foundUs.advertisement}*/}
                    {/*               onChange={handleChange}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <label className="form-check-label" htmlFor="search">Google Search</label>*/}
                    {/*        <input className="form-check-input" name="foundUs" type="checkbox"*/}
                    {/*               checked={contactInfo.foundUs.googleSearch} onChange={handleChange}*/}
                    {/*               id="googleSearch"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <label className="form-check-label" htmlFor="throughAFriend">Through a Friend</label>*/}
                    {/*        <input className="form-check-input" name="foundUs" type="checkbox"*/}
                    {/*               checked={contactInfo.foundUs.throughAFriend}*/}
                    {/*               id="throughAFriend" onChange={handleChange}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <label className="form-check-label" htmlFor="socialMedia">Social Media</label>*/}
                    {/*        <input className="form-check-input" name="foundUs" type="checkbox"*/}
                    {/*               checked={contactInfo.foundUs.socialMedia} onChange={handleChange}*/}
                    {/*               id="socialMedia"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <label className="form-check-label" htmlFor="other">Other</label>*/}
                    {/*        <input className="form-check-input" data-bs-toggle="collapse" href="#collapse" role="button"*/}
                    {/*               aria-expanded="false" aria-controls="collapseExample" name="foundUs" type="checkbox"*/}
                    {/*               id="other"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="collapse" id="collapse">*/}
                    <div className="mt-3">
                        <div className="mb-3">
                            <label htmlFor="controlSubjectInput" className="form-label">Subject*</label>
                            <input type="text" className="form-control" id="controlSubjectInput" name={'subject'} required
                                   placeholder="Business Inquiry" value={contactInfo.foundUs.other.subject}
                                   onChange={handleChange}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="controlOtherInput" className="form-label">Details*</label>
                            <textarea className="form-control" style={{resize: "none", height: "100px"}}
                                      id="controlOtherInput" name="details" required
                                      placeholder="Business inquiry details..."
                                      value={contactInfo.foundUs.other.details} onChange={(handleChange)}></textarea>
                        </div>
                    </div>
                    {/*</div>*/}

                    <div className="col-12 my-4">
                        <button className="" type="submit">Submit form</button>
                    </div>

                    <div className="row d-flex flex-column flex-lg-row justify-content-between align-items-center">
                        <div className="fax col-3 d-flex justify-content-around align-items-center">
                            <TypeWriterIcon/>
                            <div className="d-flex flex-column justify-content-center">
                                <p className="fw-bold">FAX</p>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderFaxTooltip}>
                                    <p className="text-green hover" onClick={() => {
                                        navigator.clipboard.writeText(fax)
                                        setCopyFaxText("Copied!")
                                        setTimeout(() => {
                                            setCopyFaxText("Copy")
                                        }, 1000)
                                    }}>{fax}</p>
                                </OverlayTrigger>
                            </div>
                        </div>

                        <div className="phone col-4 d-flex justify-content-around align-items-center p-0">
                            <BiPhoneCall size={32}/>
                            <div className="d-flex flex-column justify-content-center">
                                <p className="fw-bold">PHONE</p>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderPhoneTooltip}>
                                    <p className="text-green hover" onClick={() => {
                                        navigator.clipboard.writeText(phoneNumber)
                                        setCopyPhoneText("Copied!")
                                        setTimeout(() => {
                                            setCopyPhoneText("Copy")
                                        }, 1000)
                                    }}>{phoneNumber}</p>
                                </OverlayTrigger>
                            </div>
                        </div>

                        <div className="email col-5 d-flex justify-content-around align-items-center">
                            <FaEnvelopeOpenText size={24} className="me-3"/>
                            <div className="d-flex flex-column justify-content-center">
                                <p className="fw-bold">EMAIL</p>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderEmailTooltip}>
                                    <a className="text-green hover" href="mailto:info@gorigeshcoffee.com">{email}</a>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section
                className="mapSection col-12 col-lg-5 d-flex justify-content-center justify-content-lg-between mt-3 p-0">
                <div className="firstMap">
                    <p className="m-0">Gori Gesha Office</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d626.627917763358!2d38.8332857108263!3d9.021501396884771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2set!4v1674385907905!5m2!1sen!2set"
                        loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="secondMap">
                    <p className="m-0">Our Farm</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18888.60277233227!2d35.466666999999994!3d6.5833329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17a893ed849308a9%3A0xa5f076e35fed2d81!2sGesha!5e1!3m2!1sen!2set!4v1674386152266!5m2!1sen!2set"
                        loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </div>
    )
}