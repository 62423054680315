import {Link, matchPath, useLocation} from "react-router-dom";
import LogoLarge from "../assets/gori-gesha-mountain-coffee-plantation-full.svg";
import {useTransform, useScroll} from "framer-motion";
import {motion} from "framer-motion";
import {useMedia} from "react-media";
import * as ROUTES from "../constants/routes";
import Logo from "../assets/gori-gesha-logo.svg";
import "../sass/navbar.scss";
import '../sass/hero.scss';

export default function (props) {
    const {pathname} = useLocation()
    const isSmall = useMedia({query: "(max-width: 768px)"})
    const matchHome = matchPath({path: ROUTES.HOME}, pathname)
    const {scrollYProgress} = useScroll()
    const scale = useTransform(scrollYProgress, [0, 0.25], [1, 0.3]);
    const height = useTransform(scrollYProgress, [0, 1], [`calc(90vh)`, `calc(10vh)`], {clamp: false});
    const padding = useTransform(scrollYProgress, [0, 0.25], [35, 12]);
    const width = useTransform(scrollYProgress, [0, 0.25], [`calc(60%)`, `calc(25%)`]);
    const xPos = useTransform(scrollYProgress, [0, 0.25], [`calc(35%)`, `calc(0%)`]);
    const yPos = useTransform(scrollYProgress, [0, 0.25], [`calc(50%)`, `calc(0%)`]);

    const match = (path)=>matchPath({path},pathname)

    if (matchHome)
        return (
            <motion.div className="hero" style={{height}}>
                {<motion.div
                    className={'logo'}
                    style={isSmall ? {width, y: yPos} : {width, x: xPos, y: yPos}}>
                    <motion.img loading="lazy"
                        style={{padding, scale}}
                        transition={{type: 'tween', duration: 1.5}}
                        className={'hero-image'} src={LogoLarge} alt={'Gori Gesha Logo'}/>
                </motion.div>}
                <nav className="navbar navbar-expand-lg">
                    <div className={`container-fluid d-flex justify-content-between `}>
                        <button className="navbar-toggler border-0 mt-2 m-sm-0" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarTogglerDemo02" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse collapse text-center" id="navbarTogglerDemo02">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className={`nav-link ${match(ROUTES.HOME)?'active':''}`} aria-current="page" to={ROUTES.HOME}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${match(ROUTES.SUSTAINABILITY)?'active':''}`} to={ROUTES.SUSTAINABILITY}>Sustainability</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${match(ROUTES.TIMELINE)?'active':''}`} to={ROUTES.TIMELINE}>Our Story</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </motion.div>)
    else return (
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
            <div className={`container-fluid d-flex justify-content-between`}>
                <Link className="navbar-brand" to={ROUTES.HOME}>
                    <img loading="lazy" loading="lazy" src={Logo} alt="Logo" className="img-fluid"/>
                </Link>
                <button className="navbar-toggler border-0 mt-2 m-sm-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo02" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse text-center" id="navbarTogglerDemo02">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`nav-link ${match(ROUTES.HOME)?'active':''}`} aria-current="page" to={ROUTES.HOME}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${match(ROUTES.SUSTAINABILITY)?'active':''}`} to={ROUTES.SUSTAINABILITY}>Sustainability</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${match(ROUTES.TIMELINE)?'active':''}`} to={ROUTES.TIMELINE}>Our Story</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}