import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import Sustainability from './components/Sustainability';
import Error from './components/Error';
import SharedLayout from './components/SharedLayout';
import * as ROUTES from './constants/routes';
import Contact from "./components/Contact";
import Timeline from "./components/Timeline";
import './sass/_globals.scss'

function App() {



    return (
        <BrowserRouter>
            <Routes>
                <Route path={ROUTES.HOME} element={<SharedLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path={ROUTES.SUSTAINABILITY} element={<Sustainability/>}/>
                    <Route path={ROUTES.TIMELINE} element={<Timeline/>}/>
                    <Route path={ROUTES.CONTACT} element={<Contact/>}/>
                    <Route path={ROUTES.ERROR} element={<Error/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;