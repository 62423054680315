import {useCallback, useEffect, useLayoutEffect, useState} from "react";
import gori_mountains from '../assets/history-of-gesha-5.webp'
import coffee_tree from '../assets/history-of-gesha-6.webp'
import coffee_beans from '../assets/history-of-gesha-2.webp'
import gorigesha_history_1 from '../assets/gorigesha-history-1.webp'
import gorigesha_history_2 from '../assets/gorigesha-history-2.webp'
import gorigesha_history_3 from '../assets/gorigesha-history-3.webp'
import gorigesha_history_4 from '../assets/gorigesha-history-4.webp'
import minit_tribe_1 from '../assets/minit-tribe-1.webp'
import minit_tribe_2 from '../assets/minit-tribe-2.webp'
import minit_tribe_3 from '../assets/minit-tribe-3.webp'
import minit_tribe_4 from '../assets/minite-tribe-4.webp'
import "../sass/timeline.scss";
import {FiChevronUp} from "@react-icons/all-files/fi/FiChevronUp";
import {FiChevronDown} from "@react-icons/all-files/fi/FiChevronDown";
import {matchPath, useLocation} from "react-router-dom";
import * as ROUTES from '../constants/routes'

export default function Timeline() {
    const [banner1, setBanner1] = useState(false);
    const [banner2, setBanner2] = useState(false);
    const [banner3, setBanner3] = useState(false);

    const {pathname, hash} = useLocation()

    const elBanner1 = useCallback(node => {

        if (node !== null && banner1) {
            node.parentNode.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }, [banner1]);
    const elBanner2 = useCallback(node => {
        if (node !== null && banner2) {
            node.parentNode.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }, [banner2]);
    const elBanner3 = useCallback(node => {
        if (node !== null && banner3) {
            node.parentNode.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }, [banner3]);

    useEffect(()=>{
        if(hash==='#history-of-gesha'){
            setBanner2(false)
            setBanner3(false)
            setBanner1(true)

        }else if(hash==='#gori-gesha'){
            setBanner1(false)
            setBanner3(false)
            setBanner2(true)

        }else if(hash==='#minit-tribe'){
            setBanner2(false)
            setBanner1(false)
            setBanner3(true)

        }



    },[])


    return (
        <main className="timeline">
            <div className={'banner'}>
                <div ref={elBanner1} className={`banner-1 ${banner1 ? 'active' : ''}`}
                     onClick={(e) => {
                         if (banner2) setBanner2(false)
                         if (banner3) setBanner3(false)
                         setBanner1(!banner1)
                     }}>
                    <p>History of Gesha</p>
                    {banner1?<FiChevronUp/>:<FiChevronDown/>}
                </div>
                <div className={`content ${banner1 ? 'active' : ''}`}>
                    <div id="history-of-gesha" className="history-of-gesha">
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p>
                                In 1931, the British consul led by Captain Richard Whalley was tasked by their
                                government to gather coffee beans for a census. They explored the southwestern highlands
                                of Ethiopia and gathered hundreds of coffee samples that were sorted, arranged, and
                                shipped to research stations globally for evaluation. Among the collected cherries, one
                                group proved to be extraordinary in taste and disease resistance. This was the beginning
                                of Gesha's journey from the Gorigesha forest of southwestern Ethiopia, through Tanzania,
                                Kenya, and Costa Rica, before finally finding its way to Panama, where a small group of
                                coffee farmers planted the unknown bean around the town of Boquete in the Chiriquí
                                province. The bean remained unknown for another 40 years before coffee producers began
                                to notice its exceptional ability to resist disease.
                            </p>
                            <img loading="lazy" src={gori_mountains} className={'left'}/>
                        </div>
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p className={'order-lg-1'}>
                                In 1996, the Peterson family, owners of Hacienda La Esmeralda, acquired another farm in
                                Boquete, Chiriqueí, which they named Esmeralda Jaramillo. Having previously been
                                plagued by coffee leaf rust, the Peterson family was surprised when they came across the
                                Gesha trees and noticed how little the fungus had affected them. Intrigued by this, they
                                decided to expand their cultivation of Gesha on the farm, even planting it on sections
                                with elevations above 1650m. This turned out to be a fateful decision, as the resulting
                                coffee turned out to be unlike anything anyone had tasted before and would eventually
                                take the coffee world by storm.
                            </p>
                            <img loading="lazy" src={coffee_tree} className={'right order-lg-0'}/>
                        </div>
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p>
                                By 2004, the family had separated the beans from each parcel in hopes of better
                                understanding the differences in their farming location. This turned out to be another
                                fateful decision when one of the lots separated from Jaramillo won the 2004 Best of
                                Panama competition, surprising everyone who tasted it. Hacienda La Esmeralda went on to
                                establish the highest price ever paid for coffee at the time, a staggering $21 per
                                pound. Today, top-quality Gesha coffee can sell for up to $2,000 per pound in the
                                international coffee market.
                            </p>
                            <img loading="lazy" src={coffee_beans} className={'left'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'banner'}>
                <div ref={elBanner2} className={`banner-2 ${banner2 ? 'active' : ''}`}
                     onClick={(e) => {
                         if (banner1) setBanner1(false)
                         if (banner3) setBanner3(false)
                         setBanner2(!banner2)
                     }}>
                    <p>Gori Gesha Mountain Coffee Plantation</p>

                    {banner2?<FiChevronUp/>:<FiChevronDown/>}
                </div>
                <div className={`content ${banner2 ? 'active' : ''}`}>
                    <div id="history-of-ethiopia" className="ethiopia">
                        {/* Gori Gesha Mountain Coffee Plantation */}
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p>
                                In 2010, a man named Yohannes Samuel ventured deep into the southwestern region of
                                Ethiopia in search of the original mother trees of the Gesha varietal. Fueled by his
                                dream of establishing his very own, sustainable coffee farm in his native country,
                                Yohannes persevered against the challenging terrain of the Ethiopian highlands,
                                continuing his search for years to come.
                            </p>
                            <img loading="lazy" src={gorigesha_history_1} className={'left'}/>
                        </div>
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p className={'order-lg-1'}>
                                In 2012 he rediscovered what the British had found in 1931. Astounded by his discovery,
                                Yohannes harvested ripe cherries from the mother trees and sent them to renowned coffee
                                specialists around the world. Piqued by the cup score of the beans, they sent them to
                                Denver Botanical Garden, to confirm what they had suspected. Gori Gesha and the
                                Panamanian geisha of Hacienda La Esmeralda were nearly identical.
                            </p>
                            <img loading="lazy" src={gorigesha_history_2} className={'right order-lg-0'}/>
                        </div>
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p>
                                Recognizing the difficulties that lay in the forest, Yohannes Samuel, along with his
                                team, began cultivating the cherries in the nearby village of ‘Gesha’. Despite
                                successfully cultivating geisha and other coffee, Yohannes couldn't help but feel that
                                his dream of cultivating the forest, although an incredibly difficult endeavor, would
                                prove to be the most rewarding. In 2016, having witnessed his previous efforts, the
                                tribe leaders approached Yohannes, entrusting him with 600 hectares of the Gorigesha
                                forest.
                            </p>
                            <img loading="lazy" src={gorigesha_history_3} className={'left'}/>
                        </div>
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p className={'order-lg-1'}>
                                Soon after receiving the land, Samuel and his team began work on cultivating the gem
                                that is now ‘the GoriGesha Mountain Coffee plantation’. By 2018, they had successfully
                                cultivated 500 hectares of the land, with future plans of cultivating the entire
                                Gorigesha
                                forest while preserving the ancient ecology.
                            </p>
                            <img loading="lazy" src={gorigesha_history_4} className={'right order-lg-0'}/>
                        </div>
                        {/**/}
                    </div>
                </div>
            </div>
            <div className={'banner'}>
                <div ref={elBanner3} className={`banner-3 ${banner3 ? 'active' : ''}`}
                     onClick={(e) => {
                         if (banner1) setBanner1(false)
                         if (banner2) setBanner2(false)
                         setBanner3(!banner3)
                     }}>
                    <p>Minit Tribe</p>

                    {banner3?<FiChevronUp/>:<FiChevronDown/>}
                </div>
                <div className={`content ${banner3 ? 'active' : ''}`}>
                    <div id="history-of-gori-gesha" className="history-of-gori-gesha">
                        {/* Minute Tribe */}
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p>
                                The region of Gesha is the ancestral home of the Minit tribe, a vibrant people whose
                                center is the nearby city of Jemu. For centuries, their existence was intertwined with
                                the endless forests of Gesha. However, as the world continued to evolve towards
                                globalization, their survival became linked to the cultivation and trade of cash crops,
                                such as corn and other agricultural products. In need of land to meet these needs, they
                                were forced to clear portions of the forest, which has resulted in significant
                                environmental degradation and loss of biodiversity.
                            </p>
                            <img loading="lazy" src={minit_tribe_1} className={'left'}/>
                        </div>
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p className={'order-lg-1'}>
                                The tribal leaders, devastated by the views of naked mountains where magnificent forests
                                once stood, decided they needed intervention. In 2012, a member of the Minit tribe
                                tasked with protecting the Gorigesha Forest took it upon himself to contact the Ministry
                                of Investment. "Send me someone to protect my land," he pleaded, "I am unable to prevent
                                my people from sacrificing the forests they need to survive. Send me someone who can
                                save our heritage without forcing hunger upon my people." This is how Yohannes' dream of
                                preserving the Gorigesha forest intersected with the lives of the Minit people, who had
                                been residing in the forests of Gesha for centuries.
                            </p>
                            <img loading="lazy" src={minit_tribe_2} className={'right order-lg-0'}/>
                        </div>
                        {/*<div*/}
                        {/*    className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>*/}
                        {/*    <p>*/}
                        {/*        “Send me an investor” he said, “For I am unable to prevent my people from sacrificing*/}
                        {/*        the forests they desperately need to survive. Send me someone who can save our heritage*/}
                        {/*        without forcing hunger upon my people.”*/}
                        {/*        And that was how Yohannes Samuel came into the lives of the minit people residing in*/}
                        {/*        the forests of Gori.*/}
                        {/*    </p>*/}

                        {/*    <img loading="lazy" src={minit_tribe_3} className={'left'}/>*/}

                        {/*</div>*/}
                        <div
                            className={'paragraph d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'}>
                            <p className={''}>
                                Despite having an abundance of naturally growing Gesha coffee, the Minit tribe lacked a
                                genuine connection to the bean. They saw little value in processing a crop that could
                                not sustain their livelihood. Yet in certain areas of the village, they indulged in a
                                drink called Chemo, a drink made from the leaves of the coffee trees along with forest
                                honey and garlic additives. It was ironic how they had chosen to roast and grind the
                                leaves similar to how the rest of the world processed the beans. It is possible that the
                                medicinal antioxidants of the leaves, passed down through tradition, held greater
                                significance for them.
                            </p>
                            <img loading="lazy" src={minit_tribe_4} className={'left'}/>
                        </div>
                        {/**/}
                    </div>
                </div>
            </div>
        </main>
    )
}